import { useEffect, useState } from 'react';
import React from 'react';
import { isConstructorDeclaration, isReturnStatement } from 'typescript';
import './App.scss';
import contract from './contracts/Web3Warrior.json';
import Grid from '@material-ui/core/Grid';
import { relative } from 'path/posix';
import Logo from './images/logo.png'
import { url } from 'inspector';
import { BsDiscord } from 'react-icons/bs'
import { ethers } from 'ethers';
import { Modal } from '@material-ui/core';
import EtherscanLogoLight from './images/etherscan-logo-light-circle.svg'
import EtherscanLogo from './images/etherscan-logo-circle.svg'
import { hashMessage } from '@ethersproject/hash';




const contractAddress = "0x34EE3C03eef766EFdC4F814d8A43eDA4A2be838F";
const abi = contract.abi;
declare var window: any;
const openseaURL = 'https://opensea.io/collection/the-symms';
const discordURL = 'https://discord.gg/q5qy7Mvjcb';
const homePageURL = 'https://thesymms.com';
const etherscanURL = 'https://etherscan.io/address/'+ contractAddress +'#code';
const publicMintStart = 1657978620000;
const txURL = 'https://etherscan.io/tx/';



function App() {


  const [currentAccount, setCurrentAccount] = useState(null);
  const [publicMint, setPublicMint] = useState(false);
  const [amount, setAmount] = useState(1);
  const [mintCount, setMintCount] = useState(191);
  const [open, setOpen] = useState(false);
  const [open2,setOpen2] = useState(false);
  const [hash, setHash] = useState(null);
  const mintCost = 0.022;
  const allowlistMintCost = 0.033;
  const [maxAmount, setMaxAmount] = useState(2);



  useEffect(() => {

    const check721Balance = async () => {
      try {
        const { ethereum } = window;

        if (ethereum) {

          const accounts = await ethereum.request({ method: 'eth_accounts' });
          const account = accounts[0];

          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const Contract = new ethers.Contract(contractAddress, abi, signer);

          console.log("Initialize payment");
          let mintCount = await Contract.totalSupply();
          console.log(parseInt(mintCount, 10), "symms was found");
          setMintCount(parseInt(mintCount, 10));


        } else {
          console.log("Ethereum object does not exist");
        }
      } catch (err) {
        console.log(err);
      }
    };
    check721Balance();
    checkWalletIsConnected();
    const time = async () => {
      if (Date.now() > publicMintStart) {
        setMaxAmount(20);
        // if (amount > 5){
        setPublicMint(true);
      }


      else {
        setMaxAmount(2);
        setPublicMint(false);
      }
    }
    time();

  }, [])

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", networkChanged);
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", networkChanged);
      }
    };
  }, [window.ethereum]);

  const time = async () => {
    if (Date.now() > publicMintStart) {
      setMaxAmount(20);
      // if (amount > 5){
      setPublicMint(true);
    }


    else {
      setMaxAmount(2);
      setPublicMint(false);
    }
  }

  const networkChanged = (chainId) => {
    console.log({ chainId });
  };

  const [error, setError] = useState(null);

  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName, setError });
  };

  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],

      });
    } catch (err) {
      console.log(err)
    }
  };


  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }
  const check721Balance = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {

        const accounts = await ethereum.request({ method: 'eth_accounts' });
        const account = accounts[0];

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const Contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        let mintCount = await Contract.totalSupply();
        console.log(parseInt(mintCount, 10), "symms was found");
        setMintCount(parseInt(mintCount, 10));


      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      handleNetworkSwitch("ethereum");
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
    check721Balance();

  }


  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        
        if (Date.now() > publicMintStart) {
          let totalCost = amount * mintCost;
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const KContract = new ethers.Contract(contractAddress, abi, signer);

          console.log("Initialize payment");
          let nftTxn = await KContract.mint(amount, { value: ethers.utils.parseEther(totalCost.toString()) });
          console.log("Mining... please wait");
          await nftTxn.wait();

          console.log('Mined, see transaction: https://etherscan.io/tx/', nftTxn.hash);
          // console.log('Mined, see transaction: https://etherscan.io/tx/', nftTxn.hash);
          setHash(nftTxn.hash);
          setOpen(true);
          check721Balance();
        } else {
          let totalCost = amount * allowlistMintCost;
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const KContract = new ethers.Contract(contractAddress, abi, signer);

          console.log("Initialize payment");
          let nftTxn = await KContract.mintPresale(amount, { value: ethers.utils.parseEther(totalCost.toString()) });
          console.log("Mining... please wait");
          await nftTxn.wait();

          console.log('Mined, see transaction: https://etherscan.io/tx/', nftTxn.hash);
          // console.log('Mined, see transaction: https://etherscan.io/tx/', nftTxn.hash);
          setHash(nftTxn.hash);
          setOpen(true);
          check721Balance();
        }


      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
      setOpen2(true);
    }
  }


  const minusHandler = async () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  const plusHandler = async () => {
    if (amount < maxAmount) {
      setAmount(amount + 1);
    }
    
  }

  const minusButton = () => {
    return (
      <div className='buttonContainer' onClick={minusHandler}>
        <a className='button click Minus'> - </a>
      </div>
    )
  }

  const plusButton = () => {
    return (
      <div className='buttonContainer' onClick={plusHandler}>
        <a className='button click plus'> + </a>
      </div>
    )
  }

  const Hidden = () => {
    return (
      <div></div>
    )
  }

  const connectWalletButton = () => {
    return (
      <div className='buttonContainer'>
        <a className='button click mint' onClick={connectWalletHandler} >
          Connect with Metamask
        </a>
      </div>
    )
  }

  const mintNftButton = () => {
    return (
      <div className='buttonContainer' onClick={mintNftHandler}>
        <a className='button click mint' >
          Mint
        </a>
      </div>
    )
  }

  const numBoxDisplay = () => {
    return (
      <div className='buttonContainer'>
        <a className='button'>
          {amount}
        </a>
      </div>
    )
  }
  const displayMintCount=()=>{
    return(
      <div>{mintCount} out of 1000 Minted</div>
    )
  }

  const allowlistMintMessage = () => {
    return (
      <div className='message'>
        <div>
          Allowlist Mint Now Live
          {/* Allowlist mint starts at 1:37pm UTC */}
        </div>
        <div>
          Price: {allowlistMintCost} ETH
        </div>
        <div>
          Max: 2 mints per transaction and wallet
        </div>
      </div>
    )
  }

  const publicMintMessage = () => {
    return (
      <div className='message'>
        <div>
          Public Mint Now Live
        </div>
        <div>
          Price: {mintCost} ETH
        </div>

      </div>
    )
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const openDiscord = () => {
    window.open(discordURL);
  }
  const viewOnOpensea = () => {
    window.open(openseaURL);
  }
  const openHomePage = () => {
    window.open(homePageURL);
  }
  const openEtherscan = () => {
    window.open(etherscanURL);
  }
  const viewTx = () => {
    window.open(txURL + hash)
  }

  return (
    <div>
      <div className='page'> {/*First Page */}
        <Modal
          open={open}
          onClose={handleClose}
        >
          <div className='modal' >
            <a className='modalText'>Transaction Completed!</a>
            <div className='button logoButton modalText click' onClick={viewTx}>
              <a className='modalText' href={'https://etherscan.io/tx/' + hash} target='_blank' rel='noreferrer' >View Transaction</a> <img className='opensea' src={EtherscanLogo} />
            </div>
            <div className='button logoButton modalText click' onClick={viewOnOpensea}>
              <a className='modalText' href={openseaURL} target='_blank' rel='noreferrer' >View On Opensea </a> <img className='opensea' src='https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg' />
            </div>

            {/* <a href={'https://etherscan.io/tx/' + hash} className='modalText'>
        {hash}
        </a> */}
          </div>
          {/* {mBody} */}

        </Modal>
        <Modal
          open={open2}
          onClose={handleClose}
        >
          <div className='modal' >
            <a className='modalText'>Transaction Failed! Please check your wallet</a>
            

            {/* <a href={'https://etherscan.io/tx/' + hash} className='modalText'>
        {hash}
        </a> */}
          </div>
          {/* {mBody} */}

        </Modal>
        <div className='content'>
          <div className='logo'>
            <img className='logoImg click' src={Logo} onClick={openHomePage} />
          </div>
          <div className='message'>
            1000 Awe-Inspiring, Unique Creatures living on the Ethereum Blockchain
          </div>
          <div>
            {publicMint ? publicMintMessage() : allowlistMintMessage()}
          </div>

          {currentAccount ? Hidden() : connectWalletButton()}
          <div className='message'>
          {currentAccount ? displayMintCount() : Hidden()}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
            {currentAccount ? minusButton() : Hidden()}
            {currentAccount ? numBoxDisplay() : Hidden()}
            {currentAccount ? plusButton() : Hidden()}
          </div>
          {currentAccount ? mintNftButton() : Hidden()}

          <div className='logoButton button click' onClick={openEtherscan}>
            <a className='' href={etherscanURL} target="_blank" rel="noreferrer">View Contract</a>
            <img className='buttonLogo' src={EtherscanLogoLight} />
          </div>


          <div style={{}}>
            If you have any problems, contact us on
          </div>
          <div className='buttonContainer'>
            <div className='logoButton button click' onClick={openDiscord}>
              <a className='' href={discordURL} target="_blank" rel="noreferrer">Discord</a>
              <BsDiscord className='buttonLogo' />
            </div>
          </div>
          <div>

          </div>
        </div>





      </div>



    </div>
  )
}

export default App;